import { DeviceType } from '../../../../stores/types/Device';
import { ProjectType } from '../../../../stores/types/Project';

export const objectsAreEqual = (a: any, b: any): boolean => {
  if (typeof a !== typeof b) {
    return false;
  }

  const t = typeof a;

  if (t === 'object') {
    if (a === null && b === null) {
      return true;
    }

    if ((a === null && b !== null) || (a !== null && b === null)) {
      return false;
    }

    for (const key of Object.keys(a)) {
      if (!Object.prototype.hasOwnProperty.call(b, key)) {
        return false;
      }

      if (!objectsAreEqual(a[key], b[key])) {
        return false;
      }
    }

    for (const key of Object.keys(b)) {
      if (!Object.prototype.hasOwnProperty.call(a, key)) {
        return false;
      }
    }

    return true;
  } else {
    return a === b;
  }
};

export const deviceHasSensor = (device: DeviceType, sensor: string): boolean => {
  if (device.latest?.data && (device.latest?.data as any)[sensor]) {
    return true;
  }

  if (
    device.latest?.stats?.sensors?.value &&
    device.latest?.stats?.sensors?.value.includes(sensor)
  ) {
    return true;
  }

  return false;
};
export const colocationGroupName = (project: ProjectType, device: DeviceType): string | null => {
  const result = project?.colocationGroups?.find(
    (gr) => gr.incrementiveId === device?.config?.loc?.g,
  );
  if (result && result.name) {
    return result.name;
  } else {
    return null;
  }
};
export const getSavedValue = (device: DeviceType, field: string, project: ProjectType): any => {
  switch (field) {
    case 'name':
      return device.name;
    case 'group':
      return device.group;
    case 'color':
      return device.color;
    case 'isPositioningActive':
      return device.isPositioningActive;
    case 'groupname':
      return colocationGroupName(project, device);
    case 'temperaturePost':
      return device.config?.post?.temperature;
    case 'temperatureEvent':
      return device.config?.event?.temperature;
    case 'currentmeterPost':
      return device.config?.post?.currentmeter;
    case 'currentmeterEvent':
      return device.config?.event?.currentmeter;
    case 'neighborsPost':
      return device.config?.post?.neighbors;
    case 'tof':
      return device.config?.tof?.o;
    case 'imp':
      return device.config?.imp;
    case 'usagePost':
      return device.config?.post?.usage;
    case 'accelerometerPost':
      return device.config?.post?.accelerometer;
    case 'magnetometerPost':
      return device.config?.post?.magnetometer;
    case 'humidityPost':
      return device.config?.post?.humidity;
    case 'humidityEvent':
      return device.config?.event?.humidity;
    case 'lightPost':
      return device.config?.post?.light;
    case 'sensorbridgeCurrentloopPost':
      return device.config?.post?.sensorbridgeCurrentloop;
    case 'sensorbridgeRTDPost':
      return device.config?.post?.sensorbridgeRTD;
    case 'sensorbridgeDigitalinPost':
      return device.config?.post?.sensorbridgeDigitalin;
    case 'lightEvent':
      return device.config?.event?.light;
    case 'pressurePost':
      return device.config?.post?.pressure;
    case 'pressureEvent':
      return device.config?.event?.pressure;
    case 'currentmeterTransformationFactor':
      return device.config?.exts?.cm?.f;
    case 'currentmeterSamplingPeriod':
      return device.config?.exts?.cm?.s;
    case 'sensorbridgeCurrentloopSamplingPeriod':
      return device.config?.exts?.sb?.f?.[1];
    case 'sensorbridgeRTDSamplingPeriod':
      return device.config?.exts?.sb?.r?.[1];
    case 'sensorbridgeDigitalinSamplingPeriod':
      return device.config?.exts?.sb?.q?.[1];
  }
};
