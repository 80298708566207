import { types } from 'mobx-state-tree';

const SensorbridgeCurrentloopValue = types.model('SensorbridgeCurrentloopValue', {
  latest: types.number,
  min: types.number,
  max: types.number,
  avgSinceLastPost: types.number,
});

export const SensorbridgeCurrentloop = types.model('SensorbridgeCurrentloop', {
  timestamp: types.string,
  value: SensorbridgeCurrentloopValue,
});

const SensorbridgeRTDValue = types.model('SensorbridgeRTDValue', {
  latest: types.number,
  min: types.number,
  max: types.number,
  avgSinceLastPost: types.number,
});

export const SensorbridgeRTD = types.model('SensorbridgeRTD', {
  timestamp: types.string,
  value: SensorbridgeRTDValue,
});

const SensorbridgeDigitalinValue = types.model('SensorbridgeDigitalinValue', {
  currentState: types.boolean,
  countSinceLastPost: types.number,
});

export const SensorbridgeDigitalin = types.model('SensorbridgeDigitalin', {
  timestamp: types.string,
  value: SensorbridgeDigitalinValue,
});
