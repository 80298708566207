import React from 'react';
import Tooltip from 'rc-tooltip';

interface TooltipTableHeaderProps {
  headerContent: string;
  tooltipContent: string;
}

const TooltipTableHeader: React.FC<TooltipTableHeaderProps> = ({
  headerContent,
  tooltipContent,
}) => {
  return (
    <th>
      <Tooltip prefixCls="rc-slider-tooltip" overlay={tooltipContent} placement="top">
        <span>{headerContent}</span>
      </Tooltip>
    </th>
  );
};

export default TooltipTableHeader;
