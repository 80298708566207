import { types } from 'mobx-state-tree';

interface IUplinkPayload {
  name: string;
  component: string;
}

interface IUplink {
  id: string;
  payload: Array<IUplinkPayload>;
}

interface ISingleComponentSchema {
  size: number;
  type: string;
  func?: string;
  unit?: string;
  littleEndian?: boolean;
  label?: string;
  hidden?: string;
}

interface IComponentSchema {
  [key: string]: ISingleComponentSchema;
}

export interface IMiotyBlueprint {
  typeEui: string;
  version: string;
  uplink: Array<IUplink>;
  component: IComponentSchema;
  downlink?: Array<IUplink>;
  meta?: string;
}

// mobx
export const SingleComponentSchema = types.model('SingleComponentSchema', {
  size: types.string,
  type: types.string,
  func: types.string,
  unit: types.string,
  littleEndian: types.boolean,
  label: types.maybe(types.string),
  hidden: types.maybe(types.boolean),
});

export const UplinkPayload = types.model('UplinkPayload', {
  name: types.string,
  component: types.string,
  condition: types.maybe(types.string),
  label: types.maybe(types.string),
  description: types.maybe(types.string),
});

const IVirtualUplink = types.model('VirtualUplink', {
  name: types.string,
  func: types.maybe(types.string),
  unit: types.maybe(types.string),
  condition: types.maybe(types.string),
});

export const Uplink = types.model('Uplink', {
  id: types.string,
  crypto: types.maybe(types.number),
  littleEndian: types.maybe(types.boolean),
  payload: types.array(UplinkPayload),
  virtual: types.maybe(IVirtualUplink),
});

export const MiotyBlueprint = types.model('MiotyBlueprint', {
  typeEui: types.string,
  version: types.string,
  meta: types.maybe(types.union(types.string, types.null)),
  uplink: Uplink,
  downlink: types.maybe(Uplink),
  component: types.map(SingleComponentSchema),
});
