import { DeviceConfigType } from '../stores/types/Config';
import { getAllPages } from './Pagination';
import { IOrganizationProject } from './types';
import { getToken } from './token';

export const Configs = (() => {
  const all = async (
    { orgId, projectId }: IOrganizationProject,
    deviceIds?: string[],
  ): Promise<DeviceConfigType[]> => {
    const token = await getToken();
    const resource = `organizations/${orgId}/projects/${projectId}/devices/configs`;

    return getAllPages<DeviceConfigType>(resource, { deviceId: deviceIds }, token);
  };

  return Object.freeze({
    all,
  });
})();
