import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, Box, TextField, Popper } from '@mui/material';
import { CountryType } from '../../../stores/types/Country';

interface Props {
  countries: CountryType[];
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void;
  value?: string;
  disabled?: boolean;
  data_cy?: string;
}

export const CountrySelect = ({
  countries,
  onChange,
  value,
  data_cy,
  disabled = false,
}: Props): React.ReactElement => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      fullWidth
      size="small"
      options={countries}
      value={countries.find((country) => country.code === value)}
      autoHighlight
      getOptionLabel={(option) => option && option.translated_name}
      disabled={disabled}
      disableClearable
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.translated_name} ({option.code})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={t('COMMON.COUNTRY')}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      PopperComponent={({ style, ...props }) => (
        <Popper
          {...props}
          style={{ ...style, height: 0 }} // width is passed in 'style' prop
        />
      )}
      ListboxProps={{ style: { maxHeight: '15rem' } }}
      onChange={onChange}
      data-cy={data_cy}
    />
  );
};
