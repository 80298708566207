import {
  // eslint-disable-next-line import/named
  Instance,
  types,
} from 'mobx-state-tree';

export const Permission = types.model('Permission', {
  isDangerous: types.boolean,
  level: types.maybe(types.enumeration(['_global', 'organization'])),
  name: types.string,
});
export type PermissionsMap = { [permission: string]: string };
export type PermissionsType = Instance<typeof Permission>;
