import React from 'react';
import Tooltip from 'rc-tooltip';

import {
  faBatteryEmpty,
  faBatteryFull,
  faBatteryHalf,
  faBatteryQuarter,
  faBatteryThreeQuarters,
  faExclamationTriangle,
  faPlug,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { BatteryValueType } from '../../../../stores/types/DeviceData/Sensors/Battery';

export const BatteryStatus = (
  t: typeof useTranslation,
  battery: BatteryValueType,
  deviceType?: string,
): React.ReactElement => {
  const chargingStatus = battery?.chargingStatus;
  const voltage = battery?.voltage;
  const level = battery?.percentage;

  let voltageInfo = '';
  let voltageWarning = false;
  if (voltage) {
    voltageInfo = ` (${Math.round(voltage * 10) / 10}V)`;
    if (voltage < 3.5) {
      voltageWarning = true;
    }
  }

  if (
    deviceType &&
    ['beacon', 'meshrouter', 'tag', 'mioty', 'externalmioty'].includes(deviceType)
  ) {
    return BatteryStatusForDevices(
      t,
      deviceType,
      chargingStatus,
      voltageInfo,
      voltageWarning,
      level,
    );
  } else {
    return BatteryStatusForSensors(t, chargingStatus);
  }
};
const BatteryStatusForDevices = (
  t: typeof useTranslation,
  deviceType: string,
  chargingStatus: string | null | undefined,
  voltageInfo: string,
  voltageWarning: boolean,
  level?: number,
): React.ReactElement => {
  switch (chargingStatus) {
    case 'CONDITIONING_DISCHARGING':
    case 'CONDITIONING_CONNECTED':
    case 'CONDITIONING_CHARGING':
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={t('COMMON.FULL_CHARGE_CONDITION') + voltageInfo}
          placement="top"
        >
          <FontAwesomeIcon icon={faPlug} color={'var(--success)'} />
        </Tooltip>
      );
    case 'CHARGING':
      if (['tag', 'mioty', 'externalmioty'].includes(deviceType)) {
        return (
          <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={t('COMMON.CHARGING') + voltageInfo}
            placement="top"
          >
            <FontAwesomeIcon icon={faPlug} />
          </Tooltip>
        );
      } else {
        return (
          <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={t('COMMON.PLUGGED_IN') + voltageInfo}
            placement="top"
          >
            <FontAwesomeIcon icon={faPlug} color="var(--success)" />
          </Tooltip>
        );
      }
    case 'FULLY_CHARGED':
      return voltageWarning ? (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={t('COMMON.FULL_CHARGE_WARNING') + voltageInfo}
          placement="top"
        >
          <FontAwesomeIcon icon={faExclamationTriangle} color="var(--danger)" />
        </Tooltip>
      ) : (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={t('COMMON.FULL_CHARGE') + voltageInfo}
          placement="top"
        >
          <FontAwesomeIcon icon={faPlug} color={'var(--success)'} />
        </Tooltip>
      );
    case 'NOT_PLUGGED':
      if (['tag', 'mioty', 'externalmioty'].includes(deviceType)) {
        if (Number.isInteger(level)) {
          const levels: any = {
            0: faBatteryEmpty,
            25: faBatteryQuarter,
            50: faBatteryHalf,
            75: faBatteryThreeQuarters,
            100: faBatteryFull,
          };

          return (
            <Tooltip
              prefixCls="rc-slider-tooltip"
              overlay={
                level! <= 25
                  ? t('COMMON.LOW_BATTERY') + voltageInfo
                  : t('COMMON.ON_BATTERY') + voltageInfo
              }
              placement="top"
            >
              <FontAwesomeIcon
                icon={levels[level as number]}
                color={
                  level === 100 ? 'var(--success)' : level! <= 25 ? 'var(--warning)' : undefined
                }
              />
            </Tooltip>
          );
        }
      } else {
        return (
          <Tooltip
            prefixCls="rc-slider-tooltip"
            overlay={
              deviceType === 'meshrouter'
                ? t('DEVICES.MESH_ROUTER_SHOULD_BE_PLUGGED') + voltageInfo
                : t('DEVICES.BEACON_SHOULD_BE_PLUGGED') + voltageInfo
            }
            placement="top"
          >
            <FontAwesomeIcon icon={faExclamationTriangle} color="var(--danger)" />
          </Tooltip>
        );
      }
      break;
    default:
      return <></>;
  }
  return <></>;
};

const BatteryStatusForSensors = (
  t: typeof useTranslation,
  chargingStatus: string | null | undefined,
): React.ReactElement => {
  switch (chargingStatus) {
    case 'CHARGING':
      return <FontAwesomeIcon icon={faPlug} />;
    case 'CONDITIONING_DISCHARGING':
    case 'CONDITIONING_CONNECTED':
    case 'CONDITIONING_CHARGING':
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={t('COMMON.FULL_CHARGE_CONDITION')}
          placement="top"
        >
          <FontAwesomeIcon icon={faPlug} color={'var(--success)'} />
        </Tooltip>
      );
    case 'FULLY_CHARGED':
      return <FontAwesomeIcon icon={faPlug} color={'var(--success)'} />;
    case 'NOT_PLUGGED':
      return <i className="pe-7s-battery info-text-color" />;
    default:
      return <></>;
  }
};
