export const getTypeName = (type: string): string => {
  if (type == 'tag') return 'Sense360';
  if (type == 'mioty') return 'MiotySense360';
  if (type == 'beacon') return 'Positioning Beacon';
  if (type == 'meshrouter') return 'Mesh Router';
  if (type == 'gateway') return 'Gateway';
  return type;
};

export const getType = (name: string): string => {
  if (name.toLowerCase() == 'sense360') return 'tag';
  if (name.toLowerCase() == 'miotysense360') return 'mioty';
  if (name.toLowerCase() == 'positioning beacon') return 'beacon';
  if (name.toLowerCase() == 'mesh router') return 'meshrouter';
  return name;
};
