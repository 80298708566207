import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TabComponent } from '../../common/Tabs/Tabs';

import './BlueprintsHint.css';
import { IMiotyBlueprint } from '../../../stores/types/MiotyBlueprints';

interface IHint {
  onClose: () => void;
  blueprints: IMiotyBlueprint[];
}

export const BlueprinstHint = ({ blueprints, onClose }: IHint): React.ReactElement => {
  const tabs = blueprints.map((blueprint, index) => {
    return {
      index: index,
      label: blueprint.typeEui,
      component: <pre>{JSON.stringify(blueprint, null, 2)}</pre>,
    };
  });

  return (
    <>
      <FontAwesomeIcon icon={faClose} onClick={onClose} className="close-button fa-lg" />
      <TabComponent tabs={tabs} />
    </>
  );
};
