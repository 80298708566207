import React, { useState } from 'react';
import './Toggle.css';

interface ToggleProps {
  text: string;
  checked: boolean;
  disabled: boolean;
  onChange: () => void;
}

export const Toggle = ({ text, checked, disabled, onChange }: ToggleProps): JSX.Element => {
  const [check, setCheck] = useState(checked);

  const offstyle = 'gray-style';
  const onstyle = 'btn-success';
  const displayStyle = check ? onstyle : offstyle;

  const handleOnChange = () => {
    onChange();
    setCheck(!check);
  };

  return (
    <>
      <label className="toggle-label">
        <span className={`default switch-wrapper`}>
          <input
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={() => handleOnChange()}
          />
          <span className={`${displayStyle} switch`}>
            <span className="switch-handle" />
          </span>
        </span>
        <span className="switch-label">{text}</span>
      </label>
    </>
  );
};
