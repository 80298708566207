export const getConsoleUrl = (): string => {
  const projectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
  switch (projectId) {
    case 'wittra-backend-testing':
      return 'https://console.alpha.wittra.se';
    case 'wittra-backend-dev':
      return 'https://console.dev.wittra.se';
    case 'cloud-next-9v6a':
      return 'https://console.next.wittra.se';
    case 'wittra':
      return 'https://console.wittra.se';
    default:
      return 'https://console.wittra.se';
  }
};
