import { LimitsType } from '../../../../stores/types/Tier';

export interface LimitType {
  id: string;
  name: string;
  limit: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TierFormatter = () => {
  /**
   * Transforms { <key>: <value> } to
   * { id: <key>, limit: <value>, name: <friendly name> }
   * minTgas & max Tags are returned under the id tagLimits
   */
  const transformLimits = (tierLimits: LimitsType): Array<LimitType> => {
    const limits = [];
    for (const key of Object.keys(tierLimits)) {
      if (key == 'id') {
        continue;
      }
      let name = '',
        limit_value = '';

      switch (key) {
        case 'storage':
          if (!tierLimits.storage) {
            limit_value = 'Custom';
          } else {
            limit_value =
              tierLimits.storage % 30 === 0
                ? String(tierLimits.storage / 30)
                : String(tierLimits.storage);
          }
          if (!tierLimits.storage) {
            name = 'Data storage';
          } else if (tierLimits.storage % 30 === 0) {
            name =
              tierLimits.storage / 30 >= 2 ? 'month of data storage' : 'months of data storage';
          } else {
            name = tierLimits.storage > 1 ? 'days of data storage' : 'day of data storage';
          }
          break;

        case 'maxUsers':
          name =
            tierLimits.maxUsers == 1 ? 'user in the organization' : 'users in the organization';
          limit_value = tierLimits.maxUsers ? String(tierLimits.maxUsers) : 'Unlimited';
          break;

        case 'support':
          name = 'E-mail support within ' + tierLimits.support + ' hours';
          // No limit
          break;

        case 'maxIntegrations':
          name =
            tierLimits.maxIntegrations == 1
              ? 'integration per project'
              : 'integrations per project';
          limit_value = tierLimits.maxIntegrations
            ? String(tierLimits.maxIntegrations)
            : 'Unlimited';
          break;

        case 'maxTags':
        case 'minTags':
          break;

        case 'dataPoints':
          name = `${tierLimits.dataPoints?.toLocaleString()} data points`;
          break;

        default:
          name = 'Unknown limit (' + key + ')';
      }
      const limit: LimitType = {
        id: key,
        limit: limit_value,
        name: name,
      };
      if (key != 'maxTags' && key != 'minTags') {
        limits.push(limit);
      }

      if (key === 'maxTags' || key === 'minTags') {
        continue;
      }
    }

    const tagLimitations: LimitType = {
      id: 'tagLimits',
      name: '',
      limit: '',
    };

    if (!tierLimits.minTags && !tierLimits.maxTags) {
      tagLimitations.limit = 'Unlimited';
      tagLimitations.name = 'Tags';
    } else if (tierLimits.minTags && !tierLimits.maxTags) {
      tagLimitations.limit = 'Min. ' + tierLimits.minTags;
      tagLimitations.name = tierLimits.minTags === 1 ? 'tag' : 'Tags';
    } else if (!tierLimits.minTags && tierLimits.maxTags) {
      tagLimitations.limit = 'Max. ' + tierLimits.maxTags;
      tagLimitations.name = tierLimits.maxTags === 1 ? 'tag' : 'Tags';
    } else if (tierLimits.minTags && tierLimits.maxTags) {
      if (tierLimits.minTags === tierLimits.maxTags) {
        tagLimitations.limit = String(tierLimits.minTags);
        tagLimitations.name = tierLimits.minTags === 1 ? 'tag' : 'Tags';
      } else {
        tagLimitations.limit = tierLimits.minTags + '-' + tierLimits.maxTags;
        tagLimitations.name = 'Tags';
      }
    }

    limits.push(tagLimitations);
    return limits;
  };

  return {
    transformLimits,
  };
};
