import React from 'react';
import { useTranslation } from 'react-i18next';

import { TierType } from '../../../../stores/types/Tier';

export interface LimitType {
  id: string;
  name: string;
  limit: string;
}

export interface SubscriptionTierProps {
  tier: TierType;
  limits?: Array<LimitType>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SubscriptionTier = ({ tier, limits }: SubscriptionTierProps) => {
  const { t } = useTranslation();
  return (
    <div>
      <h3>{tier.name}</h3>
      {tier.priceModel && (
        <div>
          {tier.priceModel.fixed === 0
            ? t('SUBSCRIPTIONS.NO_FEE')
            : t('SUBSCRIPTIONS.USD_PER_MONTH', {
                amount: (tier.priceModel.fixed / 100).toFixed(2),
              })}
        </div>
      )}

      {limits && (
        <div>
          {t('SUBSCRIPTIONS.INCLUDES')}

          <ul>
            {limits.map((limit: LimitType) => (
              <li key={`limit-${limit.id}-${tier.id}`}>
                {limit.limit} {limit.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
