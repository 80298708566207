import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, Button } from 'reactstrap';
import Loader from 'react-loaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { OrganizationType } from '../../../../modules/Organizations/store/types/Organization';
import { TierType } from '../../../../stores/types/Tier';
import { TierFormatter } from '../helpers/tierHelper';
import { SubscriptionTier } from './SubscriptionTier';

export interface SubscriptionTiersProps {
  tiers: Array<TierType>;
  onSelectTier: (id: string) => void;
  loadingOrganization: boolean;
  organization?: OrganizationType;
  inactiveSubscriptions?: number;
  loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SubscriptionTiers = ({
  onSelectTier,
  organization,
  loadingOrganization,
  tiers,
  inactiveSubscriptions,
  loading,
}: SubscriptionTiersProps) => {
  const [tiersClone, setTiersClone] = useState<TierType[]>([]);
  const { transformLimits } = TierFormatter();
  const { t } = useTranslation();

  useEffect(() => {
    if (tiers) {
      if (tiersClone.length > 0) {
        return;
      }
      let tiersClone_ = JSON.parse(JSON.stringify(tiers));
      tiersClone_ =
        (inactiveSubscriptions === 0 && !organization?.activeSubscription) ||
        organization?.activeSubscription?.tier?.name === 'Free Trial'
          ? tiersClone_
          : tiersClone_.filter((x: TierType) => x.name !== 'Free Trial');

      setTiersClone(tiersClone_);
    }
  }, [inactiveSubscriptions, organization, tiers, tiersClone.length]);
  return (
    <Card tabs="true" className="mb-3">
      <CardHeader className="card-header-tab">
        <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
          {t('SUBSCRIPTIONS.UPDATING_TIER')}
        </div>
      </CardHeader>
      <CardBody>
        {loading && (
          <div className="loader-container subscriptions-loader">
            <div className="loader-container-inner">
              <div className="text-center">
                <Loader type="ball-triangle-path" active={true} />
              </div>
            </div>
          </div>
        )}
        <div
          style={{ display: 'flex', overflowX: 'auto', paddingBottom: '30px' }}
          data-cy="tiers-table"
        >
          {tiersClone?.map((tier: TierType, index) => (
            <div
              className="px-2"
              key={`${tier.id}-${index}`}
              style={{
                width: 'max(300px, calc(100% / ' + tiersClone.length + '))',
                flexGrow: 0,
                flexShrink: 0,
                // this below is to ensure that the subscription buttons align
                // at the end of the tier box
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              data-cy={`tier-${tier.name.toUpperCase()}`}
            >
              <SubscriptionTier tier={tier} limits={tier.limits && transformLimits(tier.limits)} />
              <div className="mt-5">
                {tier.name === 'Free Trial' && (
                  <div style={{ display: 'flex' }}>
                    <FontAwesomeIcon className="mr-1" icon={faExclamationCircle} />
                    <p style={{ fontSize: '0.75rem' }}>{t('SUBSCRIPTIONS.CANCELLED')}</p>
                  </div>
                )}

                {(tier.state === 'available' || tier.state === 'conditional') &&
                  ![
                    organization?.activeSubscription?.tier?.name,
                    organization?.nextSubscription?.tier?.name,
                  ].includes(tier.name) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onSelectTier(tier.id)}
                      style={{ textTransform: 'uppercase' }}
                      disabled={loadingOrganization || loading}
                      data-cy={`subscribe-${tier.name.toUpperCase()}`}
                    >
                      {tier.state === 'available' && t('SUBSCRIPTIONS.SUBSCRIBE')}
                      {tier.state === 'conditional' && t('SUBSCRIPTIONS.START_TRIAL')}
                    </Button>
                  )}

                {organization?.activeSubscription?.tier?.id === tier.id && (
                  <Button
                    variant="contained"
                    outline
                    disabled
                    color="secondary"
                    style={{ textTransform: 'uppercase' }}
                    data-cy="current-tier"
                  >
                    {t('SUBSCRIPTIONS.CURRENT_TIER')}
                  </Button>
                )}

                {organization?.nextSubscription &&
                  organization.nextSubscription.tier?.id === tier.id && (
                    <Button
                      outline
                      disabled
                      variant="contained"
                      color="success"
                      style={{ textTransform: 'uppercase' }}
                      data-cy="next-tier"
                    >
                      {t('SUBSCRIPTIONS.DOWNGRADE')}
                      {moment(organization!.nextSubscription!.activatedAt).from(moment())}
                    </Button>
                  )}

                {organization?.activeSubscription?.tier?.id !== tier.id &&
                  tier.state === 'requires_contact' && (
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href="https://www.wittra.se/about-us/#contact"
                        style={{ position: 'absolute', bottom: '100px' }}
                      >
                        {t('SUBSCRIPTIONS.CONTACT_US')}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-5 font-italic">{t('SUBSCRIPTIONS.CONTACT_US_DESC')}</div>
      </CardBody>
    </Card>
  );
};
