export const formatInterval = (
  interval?: number,
  disabledLabel?: string,
  short?: boolean,
): string => {
  if (!interval) {
    return disabledLabel === undefined ? 'Disabled' : disabledLabel;
  }

  const days = Math.floor(interval / 86400);

  if (days >= 1) {
    return days + (short ? 'd' : ' ' + (days === 1 ? 'day' : 'days'));
  }

  const hours = Math.floor(interval / 3600);

  if (hours >= 1) {
    return hours + (short ? 'h' : ' ' + (hours === 1 ? 'hour' : 'hours'));
  }

  const minutes = Math.floor(interval / 60);

  if (minutes >= 1) {
    return minutes + (short ? 'm' : ' ' + (minutes === 1 ? 'minute' : 'minutes'));
  }

  return interval + (short ? 's' : ' ' + (interval === 1 ? 'second' : 'seconds'));
};
export const possiblePostIntervals = [
  0, 60, 300, 600, 900, 1200, 1800, 3600, 7200, 10800, 21600, 43200, 86400,
];
export const possibleEventIntervals = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 45, 60, 120,
].map((x) => x * 60);
