import React, { useEffect, useState } from 'react';
import { observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Tooltip from 'rc-tooltip';
import { useNavigate } from 'react-router-dom';
import Slider from 'rc-slider';
import { CirclePicker } from 'react-color';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import cx from 'classnames';
import { GiSettingsKnobs } from 'react-icons/gi';
import { useProjectStore } from '../../../stores/ProjectStore';
import { useProfileStore } from '../../../stores/ProfileStore';
import { DeviceType } from '../../../stores/types/Device';
import { getTypeName } from '../../../helpers/deviceType';
import { notifyError } from '../../../UI/components/notifications/error';
import { notifySuccess } from '../../../UI/components/notifications/success';
import { notifyWarning } from '../../../UI/components/notifications/warning';
import {
  DeviceEventConfig,
  ICMConfig,
  IExtsConfig,
  IImpConfig,
  IPostContent,
  ISBConfig,
  ITofConfig,
} from '../../../api/types';
import { usePusher } from '../../../context/PusherContext';
import { EditDeviceEventConfiguration } from './components/EditDeviceEventConfiguration';
import { formatInterval } from './helpers/postingIntervals';
import { EditDeviceIntervalConfiguration } from './components/EditDeviceIntervalConfiguration';
import { EditExternalSensorConfig } from './components/EditExternalSensorConfig';
import { ToggleDeviceConfiguration } from './components/ToggleDeviceConfiguration';
import {
  objectsAreEqual,
  deviceHasSensor,
  getSavedValue,
  colocationGroupName,
} from './helpers/deviceHelper';
import TooltipTableHeader from './components/TooltipTableHeader';

interface ConfigProps {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  errors: any;
  setErrors: (v: any) => void;
  intervals: number[];
  setWarnUser: (value: boolean) => void;
}

const useStoreData = () => {
  const { projectStore } = useProjectStore();
  const { profileStore } = useProfileStore();

  return useObserver(() => ({
    userRolePermissions: profileStore.rolePermissions,
    project: projectStore.project,
    devices: projectStore.devices,
    selectedDevices: projectStore.selectedDevices,
    setDeviceSelection: projectStore.setDeviceSelection,
    saveDeviceName: projectStore.renameDevice,
    saveDeviceColor: projectStore.setDeviceColor,
    saveDeviceGroup: projectStore.setDeviceGroupLabel,
    saveDeviceIsPositioningActive: projectStore.setIsPositioningActive,
    saveColocationGroup: projectStore.setColocationGroupName,
    saveDeviceConfig: projectStore.modifyDeviceConfig,
    getProject: projectStore.getProject,
    getDevices: projectStore.getDevices,
  }));
};

export const DeviceConfiguration = observer(
  ({ isSaving, errors, intervals, setIsSaving, setErrors, setWarnUser }: ConfigProps) => {
    const {
      userRolePermissions,
      project,
      devices,
      selectedDevices,
      setDeviceSelection,
      saveDeviceName,
      saveDeviceIsPositioningActive,
      saveDeviceColor,
      saveDeviceGroup,
      saveColocationGroup,
      saveDeviceConfig,
      getProject,
      getDevices,
    } = useStoreData();
    const pusherContext = usePusher();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [changes, setChanges] = useState<any>({});
    const [globalPostInterval, setGlobalPostInterval] = useState(-1);

    const connectionId =
      pusherContext && pusherContext.connectionId ? pusherContext.connectionId : '';

    if (!project || !devices) {
      return <></>;
    }
    const devicesDependency = devices.slice();

    const getDeviceChanges = (device: DeviceType, field: string, defaultValue: any = undefined) => {
      let changesToReturn: any;

      if (!changes[device.deviceId]) {
        changesToReturn = {};

        setChanges((p: any) => ({
          ...p,
          [device.deviceId]: changesToReturn,
        }));
      } else {
        changesToReturn = changes[device.deviceId];
      }

      if (!Object.prototype.hasOwnProperty.call(changesToReturn, field)) {
        return defaultValue;
      } else {
        return changesToReturn[field];
      }
    };

    const setDeviceChanges = (device: DeviceType, field: string, value: any) => {
      setChanges((p: any) => ({
        ...p,
        [device.deviceId]: {
          ...p[device.deviceId],
          [field]: value,
        },
      }));
    };

    const hasChanges = (device: DeviceType, field: string) => {
      const NO_VALUE = {};

      const savedValue = getSavedValue(device, field, project);
      const changedValue = getDeviceChanges(device, field, NO_VALUE);
      if (changedValue === NO_VALUE) {
        return false;
      }

      return !objectsAreEqual(savedValue, changedValue);
    };

    const setDeviceError = (device: DeviceType, error: any) => {
      let message = t('DEVICES.ERROR_UNKNOWN');

      if (error.response) {
        if (error.response.status === 400) {
          const m = error.response.data.message;

          if (Array.isArray(m)) {
            message = m.join(' ');
          } else if (typeof m === 'string') {
            message = m;
          }
        } else if (error.response.status === 404) {
          message = t('DEVICES.ERROR_DEVICE');
        }
      } else {
        message = t('DEVICES.ERROR_CONNECTION');
      }

      setErrors((p: any) => ({
        ...p,
        [device.deviceId]: message,
      }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const checkUserWarning = (selectedDevicesIds: string[]) => {
      for (const deviceId of selectedDevicesIds) {
        const device = devices.find((x) => x.deviceId === deviceId);
        if (!device) {
          continue;
        }
        if (
          device.deviceType == 'mioty' &&
          [
            'temperaturePost',
            'temperatureEvent',
            'currentmeterPost',
            'currentmeterEvent',
            'neighborsPost',
            'neighborsEvent',
            'tof',
            'imp',
            'usagePost',
            'usageEvent',
            'accelerometerPost',
            'magnetometerPost',
            'humidityPost',
            'humidityEvent',
            'lightPost',
            'lightEvent',
            'pressurePost',
            'pressureEvent',
            'sensorbridgeCurrentloopPost',
            'sensorbridgeRTDPost',
            'sensorbridgeDigitalinPost',
          ]
            .map((x) => hasChanges(device, x))
            .includes(true)
        ) {
          setWarnUser(true);
        }
      }
    };

    const saveName = async (device: DeviceType) => {
      const newName = getDeviceChanges(device, 'name');

      try {
        await saveDeviceName(device.deviceId, newName, connectionId);
        return true;
      } catch (error) {
        setDeviceError(device, error);
        return false;
      }
    };

    const saveGroup = async (device: DeviceType) => {
      const newGroup = getDeviceChanges(device, 'group');

      try {
        await saveDeviceGroup({
          orgId: project.organizationId,
          projectId: project.id,
          deviceId: device.deviceId,
          label: newGroup,
          connectionId,
        });

        return true;
      } catch (error) {
        setDeviceError(device, error);
        return false;
      }
    };

    const saveGroupName = async (device: DeviceType) => {
      const newGroup = getDeviceChanges(device, 'groupname');

      try {
        await saveColocationGroup({
          orgId: project.organizationId,
          projectId: project.id,
          deviceId: device.deviceId,
          label: newGroup,
          connectionId,
        });

        return true;
      } catch (error) {
        setDeviceError(device, error);
        return false;
      }
    };

    const saveIsPositioningActive = async (device: DeviceType) => {
      const newIsPositioningActive = getDeviceChanges(device, 'isPositioningActive');

      try {
        await saveDeviceIsPositioningActive({
          orgId: project.organizationId,
          projectId: project.id,
          deviceId: device.deviceId,
          isPositioningActive: newIsPositioningActive,
          connectionId,
        });

        return true;
      } catch (error) {
        setDeviceError(device, error);
        return false;
      }
    };

    const saveColor = async (device: DeviceType) => {
      const newColor = getDeviceChanges(device, 'color');

      try {
        await saveDeviceColor({
          orgId: project.organizationId,
          projectId: project.id,
          deviceId: device.deviceId,
          color: newColor,
          connectionId,
        });

        return true;
      } catch (error) {
        setDeviceError(device, error);
        return false;
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const saveDeviceChanges = async (device: DeviceType) => {
      let success = false;
      if (hasChanges(device, 'name')) {
        success = await saveName(device);
      }
      if (hasChanges(device, 'group')) {
        success = await saveGroup(device);
      }

      if (hasChanges(device, 'groupname')) {
        success = await saveGroupName(device);
      }

      if (hasChanges(device, 'isPositioningActive')) {
        success = await saveIsPositioningActive(device);
      }

      if (hasChanges(device, 'color')) {
        success = await saveColor(device);
      }

      if (
        [
          'temperaturePost',
          'temperatureEvent',
          'currentmeterPost',
          'currentmeterEvent',
          'neighborsPost',
          'neighborsEvent',
          'tof',
          'imp',
          'usagePost',
          'usageEvent',
          'accelerometerPost',
          'magnetometerPost',
          'humidityPost',
          'humidityEvent',
          'lightPost',
          'lightEvent',
          'pressurePost',
          'pressureEvent',
          'currentmeterTransformationFactor',
          'currentmeterSamplingPeriod',
          'sensorbridgeCurrentloopPost',
          'sensorbridgeCurrentloopSamplingPeriod',
          'sensorbridgeRTDPost',
          'sensorbridgeRTDSamplingPeriod',
          'sensorbridgeDigitalinPost',
          'sensorbridgeDigitalinSamplingPeriod',
        ]
          .map((x) => hasChanges(device, x))
          .includes(true)
      ) {
        const newPost: IPostContent = {
          ...device.config?.post,
        };

        const newEvent: DeviceEventConfig = {
          ...device.config?.event,
        };

        const newTofConfig: ITofConfig = {
          ...device.config?.tof,
        };

        const newImpConfig: IImpConfig = {
          ...device.config?.imp,
        };

        const newExtsConfig: IExtsConfig = {
          ...device.config?.exts,
        };

        const newCmconfig: ICMConfig = {
          ...device.config?.exts?.cm,
        };

        const newSbconfig: ISBConfig = {
          ...device.config?.exts?.sb,
        };

        if (hasChanges(device, 'temperaturePost')) {
          newPost.temperature = getDeviceChanges(device, 'temperaturePost');
        }

        if (hasChanges(device, 'temperatureEvent')) {
          newEvent.temperature = getDeviceChanges(device, 'temperatureEvent');
        }

        if (hasChanges(device, 'currentmeterPost')) {
          newPost.currentmeter = getDeviceChanges(device, 'currentmeterPost');
        }

        if (hasChanges(device, 'currentmeterEvent')) {
          newEvent.currentmeter = getDeviceChanges(device, 'currentmeterEvent');
        }

        if (hasChanges(device, 'neighborsPost')) {
          newPost.neighbors = getDeviceChanges(device, 'neighborsPost');
        }

        if (hasChanges(device, 'tof')) {
          newTofConfig.o = getDeviceChanges(device, 'tof');
        }

        if (hasChanges(device, 'imp')) {
          const axes = getDeviceChanges(device, 'imp');
          newImpConfig.e = axes.e;
          newImpConfig.t = axes && axes.t / 1000;
        }
        if (hasChanges(device, 'currentmeterTransformationFactor')) {
          const transformationFactor = getDeviceChanges(device, 'currentmeterTransformationFactor');
          newCmconfig.f = parseInt(transformationFactor);
          newExtsConfig.cm = newCmconfig;
        }

        if (hasChanges(device, 'currentmeterSamplingPeriod')) {
          const samplingPeriod = getDeviceChanges(device, 'currentmeterSamplingPeriod');
          newCmconfig.s = parseInt(samplingPeriod);
          newExtsConfig.cm = newCmconfig;
        }

        if (hasChanges(device, 'usagePost')) {
          newPost.usage = getDeviceChanges(device, 'usagePost');
        }
        if (hasChanges(device, 'accelerometerPost')) {
          newPost.accelerometer = getDeviceChanges(device, 'accelerometerPost');
        }

        if (hasChanges(device, 'magnetometerPost')) {
          newPost.magnetometer = getDeviceChanges(device, 'magnetometerPost');
        }

        if (hasChanges(device, 'humidityPost')) {
          newPost.humidity = getDeviceChanges(device, 'humidityPost');
        }

        if (hasChanges(device, 'humidityEvent')) {
          newEvent.humidity = getDeviceChanges(device, 'humidityEvent');
        }

        if (hasChanges(device, 'lightPost')) {
          newPost.light = getDeviceChanges(device, 'lightPost');
        }

        if (hasChanges(device, 'lightEvent')) {
          newEvent.light = getDeviceChanges(device, 'lightEvent');
        }

        if (hasChanges(device, 'pressurePost')) {
          newPost.pressure = getDeviceChanges(device, 'pressurePost');
        }

        if (hasChanges(device, 'pressureEvent')) {
          newEvent.pressure = getDeviceChanges(device, 'pressureEvent');
        }

        if (hasChanges(device, 'sensorbridgeCurrentloopPost')) {
          newPost.sensorbridgeCurrentloop = getDeviceChanges(device, 'sensorbridgeCurrentloopPost');
        }

        if (hasChanges(device, 'sensorbridgeCurrentloopSamplingPeriod')) {
          const samplingPeriod = getDeviceChanges(device, 'sensorbridgeCurrentloopSamplingPeriod');
          // https://github.com/wittra/iot-core-specification/pull/99/files
          // bitfield = 1
          newSbconfig.f = [newSbconfig.f ? newSbconfig.f[0] : 1, parseInt(samplingPeriod)];
          newExtsConfig.sb = newSbconfig;
        }

        if (hasChanges(device, 'sensorbridgeRTDPost')) {
          newPost.sensorbridgeRTD = getDeviceChanges(device, 'sensorbridgeRTDPost');
        }

        if (hasChanges(device, 'sensorbridgeRTDSamplingPeriod')) {
          const samplingPeriod = getDeviceChanges(device, 'sensorbridgeRTDSamplingPeriod');
          // https://github.com/wittra/iot-core-specification/pull/99/files
          // bitfield = 1
          newSbconfig.r = [newSbconfig.r ? newSbconfig.r[0] : 1, parseInt(samplingPeriod)];
          newExtsConfig.sb = newSbconfig;
        }

        if (hasChanges(device, 'sensorbridgeDigitalinPost')) {
          newPost.sensorbridgeDigitalin = getDeviceChanges(device, 'sensorbridgeDigitalinPost');
        }

        if (hasChanges(device, 'sensorbridgeDigitalinSamplingPeriod')) {
          const samplingPeriod = getDeviceChanges(device, 'sensorbridgeDigitalinSamplingPeriod');
          // https://github.com/wittra/iot-core-specification/pull/99/files
          // bitfield = 1
          newSbconfig.q = [newSbconfig.q ? newSbconfig.q[0] : 1, parseInt(samplingPeriod)];
          newExtsConfig.sb = newSbconfig;
        }
        try {
          await saveDeviceConfig({
            orgId: project.organizationId,
            projectId: project.id,
            deviceId: device.deviceId,
            post: newPost,
            event: newEvent,
            tof: newTofConfig,
            imp: newImpConfig,
            exts: newExtsConfig,
            connectionId,
          });
          success = true;
        } catch (error) {
          setDeviceError(device, error);
          return success;
        }
      }
      return success;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const notifyUser = (success: number, fail: number) => {
      if (fail + success === 0) {
        notifyError(t('DEVICES.ERROR_NO_CHANGE'));
      } else if (fail > 0 && success === 0) {
        notifyError(t('DEVICES.FAILED_TO_SAVE_CHANGES'));
      } else if (fail === 0 && success > 0) {
        notifySuccess(t('DEVICES.SAVE_CHANGES'));
        checkUserWarning(selectedDevices);
      } else {
        checkUserWarning(selectedDevices);
        notifyWarning(
          t('DEVICES.SAVE_CHANGES_WITH_ERROR', {
            successCount: success,
            errorCount: fail,
          }),
        );
      }
    };

    useEffect(() => {
      if (isSaving && Object.keys(selectedDevices).length > 0) {
        setErrors({});
        setIsSaving(true);

        let successCount = 0;
        let failCount = 0;
        const saveChange = async () => {
          for (const deviceId of selectedDevices) {
            const device = devices.find((x: DeviceType) => x.deviceId === deviceId);

            if (!device) {
              continue;
            }
            const success = await saveDeviceChanges(device);
            success ? successCount++ : failCount++;
          }
          if (successCount + failCount == selectedDevices.length) {
            notifyUser(successCount, failCount);
            setDeviceSelection([]);
          }
          await getDevices(project.organizationId, project.id);
          await getProject(project.organizationId, project.id);
        };

        saveChange();
        setIsSaving(false);
      }
    }, [
      setIsSaving,
      setErrors,
      selectedDevices,
      devices,
      devicesDependency,
      saveDeviceChanges,
      getDevices,
      project,
      getProject,
      notifyUser,
      setDeviceSelection,
      isSaving,
    ]);

    const configInterval = (device: DeviceType, field: string, defaultValue: any) => {
      if (!selectedDevices) {
        return <></>;
      }
      const disabled = isSaving || !selectedDevices.includes(device.deviceId);

      return (
        <EditDeviceIntervalConfiguration
          disabled={disabled}
          field={field}
          device={device}
          initialValue={getDeviceChanges(device, field, defaultValue)}
          save={(value: any) => setDeviceChanges(device, field, value)}
          color={hasChanges(device, field) ? 'info' : 'primary'}
        />
      );
    };

    const configIntervalForAll = (field: string) => {
      if (!selectedDevices) {
        return <></>;
      }

      const disabled = isSaving || selectedDevices.length === 0;
      return (
        <EditDeviceIntervalConfiguration
          disabled={disabled}
          field={field}
          save={(value: any) => setForSelected(field, value)}
          color={disabled ? undefined : 'primary'}
          className={disabled ? 'batchDisabled' : undefined}
          selectionCount={selectedDevices.length}
        />
      );
    };

    const configEvent = (device: DeviceType, field: string, defaultValue: any) => {
      if (!selectedDevices) {
        return <></>;
      }
      const disabled = isSaving || !selectedDevices.includes(device.deviceId);
      return (
        <EditDeviceEventConfiguration
          disabled={disabled}
          field={field}
          device={device}
          initialValue={getDeviceChanges(device, field, defaultValue)}
          save={(value: any) => setDeviceChanges(device, field, value)}
          color={hasChanges(device, field) ? 'info' : 'primary'}
        />
      );
    };

    const configEventForAll = (field: string) => {
      if (!selectedDevices) {
        return <></>;
      }
      const disabled = isSaving || selectedDevices.length === 0;

      return (
        <EditDeviceEventConfiguration
          disabled={disabled}
          field={field}
          save={(value: any) => setForSelected(field, value)}
          color={disabled ? undefined : 'primary'}
          className={disabled ? 'batchDisabled' : undefined}
          selectionCount={selectedDevices.length}
        />
      );
    };

    const configToggle = (device: DeviceType, field: string, defaultValue: any) => {
      if (!selectedDevices) {
        return <></>;
      }

      const disabled = isSaving || !selectedDevices.includes(device.deviceId);

      return (
        <ToggleDeviceConfiguration
          disabled={disabled}
          field={field}
          device={device}
          initialValue={getDeviceChanges(device, field, defaultValue)}
          save={(value: any) => setDeviceChanges(device, field, value)}
          color={hasChanges(device, field) ? 'info' : 'primary'}
        />
      );
    };

    const configToggleForAll = (field: string) => {
      if (!selectedDevices) {
        return <></>;
      }

      const disabled = isSaving || selectedDevices.length === 0;

      return (
        <ToggleDeviceConfiguration
          disabled={disabled}
          field={field}
          save={(value: any) => setForSelected(field, value)}
          color={disabled ? undefined : 'primary'}
          className={disabled ? 'batchDisabled' : undefined}
        />
      );
    };

    const configGroupForAll = (field: string) => {
      if (!selectedDevices) {
        return <></>;
      }

      let existingGroups = devices
        .map((x) =>
          field === 'group'
            ? getDeviceChanges(x, field, x.group)
            : getDeviceChanges(x, field, colocationGroupName(project, x)),
        )
        .filter((x) => x !== null)
        .filter((v, i, s) => s.indexOf(v) === i);
      const disabled = isSaving || selectedDevices.length === 0 || existingGroups.length === 0;

      if (existingGroups.length > 0) {
        field === 'group'
          ? (existingGroups = [null, ...existingGroups])
          : (existingGroups = [...existingGroups]);
      }

      return (
        <UncontrolledDropdown disabled={disabled}>
          <DropdownToggle
            style={field === 'group' ? { width: '100px' } : { width: '160px' }}
            className={disabled ? 'batchDisabled' : undefined}
            color={disabled ? undefined : 'primary'}
            disabled={disabled}
          >
            {field === 'group' ? t('DEVICES.SET_GROUP') : t('DEVICES.SET_COLOCATION_GROUP')}
          </DropdownToggle>

          <DropdownMenu>
            {existingGroups.map((existingGroup) => (
              <DropdownItem
                key={(field === 'group' ? 'group-' : 'groupname-') + existingGroup}
                onClick={() => setForSelected(field, existingGroup)}
              >
                {existingGroup || '(no group)'}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    };

    const configText = (device: DeviceType, field: string, defaultValue: any) => {
      if (!selectedDevices) {
        return <></>;
      }

      return (
        <Input
          type="text"
          value={getDeviceChanges(device, field, defaultValue) || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
            setDeviceChanges(device, field, event.target.value || null)
          }
          className={hasChanges(device, field) ? 'bg-info' : undefined}
          disabled={isSaving || !selectedDevices.includes(device.deviceId)}
          maxLength={100}
          style={{
            width: '150px',
            display: 'inline-block',
          }}
        />
      );
    };

    const configExternalSensor = (device: DeviceType, field: string, defaultValue: any) => {
      if (!selectedDevices) {
        return <></>;
      }
      const disabled = isSaving || !selectedDevices.includes(device.deviceId);

      return (
        <EditExternalSensorConfig
          disabled={disabled}
          field={field}
          device={device}
          initialValue={getDeviceChanges(device, field, defaultValue)}
          save={(value: any) => setDeviceChanges(device, field, value)}
          color={hasChanges(device, field) ? 'info' : 'primary'}
        />
      );
    };

    const setForSelected = async (field: string, value: any) => {
      if (!selectedDevices) {
        return;
      }

      const sharedFields = ['name', 'color', 'group', 'groupname', 'isPositioningActive'];

      const tagFields = [
        ...sharedFields,
        'temperaturePost',
        'temperatureEvent',
        'currentmeterPost',
        'currentmeterEvent',
        'neighborsPost',
        'tof',
        'imp',
        'usagePost',
        'accelerometerPost',
        'accelerometerEvent',
        'magnetometerPost',
        'magnetometerEvent',
        'humidityPost',
        'humidityEvent',
        'lightPost',
        'lightEvent',
        'pressurePost',
        'pressureEvent',
        'currentmeterTransformationFactor',
        'currentmeterSamplingPeriod',
        'sensorbridgeCurrentloopPost',
        'sensorbridgeCurrentloopSamplingPeriod',
        'sensorbridgeRTDPost',
        'sensorbridgeRTDSamplingPeriod',
        'sensorbridgeDigitalinPost',
        'sensorbridgeDigitalinSamplingPeriod',
      ];
      const deviceTypeToField: any = {
        beacon: [...sharedFields],
        gateway: [...sharedFields],
        meshrouter: [...sharedFields],
        tag: [...tagFields],
        mioty: [...tagFields],
        externalmioty: [...sharedFields],
      };

      for (const deviceId of selectedDevices) {
        const device = devices.find((x) => x.deviceId === deviceId);

        if (!device) {
          continue;
        }

        if (!deviceTypeToField[device.deviceType].includes(field)) {
          continue;
        }

        if (!Object.prototype.hasOwnProperty.call(changes, device.deviceId)) {
          changes[device.deviceId] = {};
        }
        changes[device.deviceId][field] = value;
      }

      setChanges({
        ...changes,
      });
    };

    const renderBatchButtons = () => {
      if (!selectedDevices) {
        return <></>;
      }

      return (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'sticky',
            top: 0,
            background: '#fff',
            zIndex: 2,
          }}
          className="mt-3 pb-2 pt-2"
        >
          {t('DEVICES.SELECT')}
          <Button
            onClick={() => setDeviceSelection(devices.map((x) => x.deviceId))}
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ALL')}
          </Button>

          <Button
            onClick={() => setDeviceSelection([])}
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.NONE')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => !selectedDevices.includes(x.deviceId)).map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.INVERTED')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'beacon').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_BEACONS')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'gateway').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_GATEWAYS')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'meshrouter').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_MESH_ROUTERS')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'tag').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_TRAKSENSE360')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'mioty').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_MIOTY')}
          </Button>

          <Button
            onClick={() =>
              setDeviceSelection(
                devices.filter((x) => x.deviceType === 'externalmioty').map((x) => x.deviceId),
              )
            }
            size="sm"
            color="primary"
            className="ml-1"
            disabled={isSaving}
          >
            {t('DEVICES.ONLY_THIRD_PARTY_MIOTY')}
          </Button>
        </span>
      );
    };

    const renderGlobalPostSlider = () => {
      if (!selectedDevices) {
        return <></>;
      }

      return (
        <div style={{ display: 'flex', alignItems: 'center', maxWidth: '70vw' }}>
          <div>{t('DEVICES.INTERVAL_POSTING')}</div>

          <Slider
            className="rc-slider-info rc-slider-square rc-slider-lg mb-3 mt-3"
            dots
            min={-1}
            step={1}
            max={intervals.length - 1}
            defaultValue={-1}
            value={globalPostInterval}
            disabled={
              (noOfTypeAreSelected('beacon') &&
                noOfTypeAreSelected('meshrouter') &&
                noOfTypeAreSelected('tag') &&
                noOfTypeAreSelected('mioty')) ||
              !noOfTypeAreSelected('gateway') ||
              !noOfTypeAreSelected('externalmioty')
            }
            handle={(props: any) => {
              const { value, dragging, index, ...restProps } = props;

              return (
                <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={value === -1 ? 'No Change' : formatInterval(intervals[value])}
                  visible={dragging}
                  placement="top"
                  key={index}
                >
                  <Slider.Handle value={value} {...restProps} />
                </Tooltip>
              );
            }}
            style={{ flexGrow: 1, marginLeft: '28px' }}
            onAfterChange={() => {
              if (globalPostInterval === -1) {
                return;
              } else {
                const commonInterval = intervals[globalPostInterval];
                const postFields = [
                  'temperaturePost',
                  'currentmeterPost',
                  'neighborsPost',
                  'usagePost',
                  'accelerometerPost',
                  'magnetometerPost',
                  'humidityPost',
                  'lightPost',
                  'pressurePost',
                ];

                for (const postField of postFields) {
                  setForSelected(postField, commonInterval);
                }
              }
            }}
            onChange={(value: any) => setGlobalPostInterval(parseInt(value))}
          />
        </div>
      );
    };

    const noOfTypeAreSelected = (deviceType: string) => {
      if (!selectedDevices) {
        return true;
      }

      for (const deviceId of selectedDevices) {
        const device = devices.find((x) => x.deviceId === deviceId);

        if (!device) {
          continue;
        }

        if (device.deviceType === deviceType) {
          return false;
        }
      }

      return true;
    };

    const showHumidity = !!(devices && devices.find((x) => deviceHasSensor(x, 'humidity')));
    const showLight = !!(devices && devices.find((x) => deviceHasSensor(x, 'light')));
    const showPressure = !!(devices && devices.find((x) => deviceHasSensor(x, 'pressure')));
    const showCurrentmeter = !!(devices && devices.find((x) => deviceHasSensor(x, 'currentmeter')));
    const showSensorbridge = !!(devices && devices.find((x) => deviceHasSensor(x, 'sensorbridge')));
    const rolePermissions: string[] = [...userRolePermissions];
    const showColocationGroup = rolePermissions && rolePermissions.includes('TAGS_COLOCATION');

    const sensorColumnCount =
      7 +
      (showHumidity ? 1 : 0) +
      (showLight ? 1 : 0) +
      (showPressure ? 1 : 0) +
      (showCurrentmeter ? 2 : 0) +
      (showSensorbridge ? 3 : 0);

    const isDisabled = isSaving || selectedDevices.length === 0;

    return (
      <Card className="p-5 mt-3">
        <div className="app-page-title app-page-title-div">
          <div
            className="page-title-wrapper"
            style={{ marginRight: '2.5rem', marginLeft: '2.5rem' }}
          >
            <div id="page-title-heading-div" className="page-title-heading">
              <div id="page-title-icon-div" className={cx('page-title-icon', { 'd-none': false })}>
                <GiSettingsKnobs />
              </div>

              <div>
                <b>{t('DEVICES.CONFIGURATION')}</b>
              </div>
            </div>

            <div className="page-title-actions">
              <Button
                onClick={() =>
                  navigate('/organizations/' + project.organizationId + '/projects/' + project.id)
                }
                size="lg"
                color="link"
              >
                {t('DEVICES.BACK')}
              </Button>
              <Button
                onClick={() => setIsSaving(true)}
                size="lg"
                color="primary"
                className="ml-2"
                disabled={isDisabled}
              >
                {t('DEVICES.SAVE')}
              </Button>
            </div>
          </div>
        </div>
        {renderBatchButtons()}
        <div className="device-manager-scroll-bar">
          <Table className="deviceBatchEdit">
            <thead>
              <tr>
                <th colSpan={5}>
                  <h4 style={{ textAlign: 'left', top: 0, zIndex: 2, position: 'sticky' }}>
                    {t('DEVICES.QUICK_CONFIG')}
                  </h4>
                </th>
                <th colSpan={sensorColumnCount + 1}>{renderGlobalPostSlider()}</th>
              </tr>

              <tr>
                <th
                  colSpan={2}
                  style={{
                    verticalAlign: 'middle',
                    textAlign: 'right',
                    fontSize: '1.3em',
                    fontWeight: 'normal',
                  }}
                >
                  {selectedDevices.length > 0 && (
                    <>
                      {selectedDevices.length === 1
                        ? t('DEVICES.CONFIGURE_DEVICE')
                        : t('DEVICES.CONFIGURE_DEVICES', {
                            count: selectedDevices.length,
                          })}
                    </>
                  )}
                </th>
                <th
                  style={{
                    zIndex: 4,
                  }}
                >
                  {configGroupForAll('group')}
                </th>
                {showColocationGroup && (
                  <th
                    style={{
                      zIndex: 3,
                    }}
                  >
                    {configGroupForAll('groupname')}
                  </th>
                )}
                <th>
                  <div style={{ padding: '5px', display: 'inline-block' }}>
                    <CirclePicker
                      className={
                        isSaving || selectedDevices.length === 0 ? 'batchDisabled' : undefined
                      }
                      circleSize={13}
                      circleSpacing={2}
                      width="135px"
                      onChangeComplete={(color) => setForSelected('color', color.hex)}
                    />
                  </div>
                </th>
                <th>{configToggleForAll('isPositioningActive')}</th>
                <th>{configToggleForAll('neighborsPost')}</th>
                <th>{configToggleForAll('tof')}</th>
                <th>
                  {configIntervalForAll('temperaturePost')}
                  {configEventForAll('temperatureEvent')}
                </th>

                {showCurrentmeter && (
                  <th colSpan={2}>
                    {configIntervalForAll('currentmeterPost')}
                    {configEventForAll('currentmeterEvent')}
                  </th>
                )}

                <th>{configIntervalForAll('usagePost')}</th>
                <th>{configIntervalForAll('accelerometerPost')}</th>
                <th>{configEventForAll('imp')}</th>

                <th>{configIntervalForAll('magnetometerPost')}</th>

                {showHumidity && (
                  <th>
                    {configIntervalForAll('humidityPost')}
                    {configEventForAll('humidityEvent')}
                  </th>
                )}

                {showLight && (
                  <th>
                    {configIntervalForAll('lightPost')}
                    {configEventForAll('lightEvent')}
                  </th>
                )}

                {showPressure && (
                  <th>
                    {configIntervalForAll('pressurePost')}
                    {configEventForAll('pressureEvent')}
                  </th>
                )}
                {showSensorbridge && (
                  <>
                    <th>{configIntervalForAll('sensorbridgeCurrentloopPost')}</th>
                    <th>{configIntervalForAll('sensorbridgeRTDPost')}</th>
                    <th>{configIntervalForAll('sensorbridgeDigitalinPost')}</th>
                  </>
                )}
              </tr>

              <tr>
                <th>{t('COMMON.DEVICE')}</th>
                <th>{t('COMMON.NAME')}</th>
                <th>{t('DEVICES.GROUP')}</th>
                {showColocationGroup && <th>{t('DEVICES.COLOCATION_GROUP')}</th>}
                <th>{t('DEVICES.COLOR')}</th>
                <TooltipTableHeader
                  headerContent={t('DEVICES.ACTIVE')}
                  tooltipContent={t('DEVICES.IS_POSITIONING_ACTIVE_EXPLANATION')}
                ></TooltipTableHeader>
                <TooltipTableHeader
                  headerContent={t('DEVICES.RSSI')}
                  tooltipContent={t('DEVICES.RSSI_EXPLANATION')}
                ></TooltipTableHeader>
                <TooltipTableHeader
                  headerContent={t('DEVICES.TOF_POSITIONING')}
                  tooltipContent={t('DEVICES.TOF_EXPLANATION')}
                ></TooltipTableHeader>
                <th>{t('DEVICES.TEMPERATURE')}</th>
                {showCurrentmeter && <th colSpan={2}>{t('DEVICES.CURRENTMETER')}</th>}

                <th>{t('DEVICES.USAGE')}</th>
                <th>{t('DEVICES.ACCELEROMETER')}</th>
                <th>{t('DEVICES.IMPACT')}</th>
                <th>{t('DEVICES.MAGNETOMETER')}</th>
                {showHumidity && <th>{t('DEVICES.HUMIDITY')}</th>}
                {showLight && <th>{t('DEVICES.LIGHT')}</th>}
                {showPressure && <th>{t('DEVICES.PRESSURE')}</th>}
                {showSensorbridge && (
                  <>
                    <th>{t('DEVICES.CURRENTLOOP')}</th>
                    <th>{t('DEVICES.RTD')}</th>
                    <th>{t('DEVICES.DIGITALIN')}</th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {devices.map((device) => (
                <tr
                  key={device.deviceId + device.config}
                  className={
                    !selectedDevices.includes(device.deviceId) ? 'batchDisabled' : undefined
                  }
                >
                  <td style={{ paddingRight: '65px' }}>
                    <div style={{ position: 'relative' }}>
                      {Object.prototype.hasOwnProperty.call(errors, device.deviceId) && (
                        <Tooltip
                          prefixCls="rc-slider-tooltip"
                          overlay={errors[device.deviceId]}
                          placement="right"
                        >
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            color="red"
                            style={{ position: 'absolute', bottom: '1px' }}
                          />
                        </Tooltip>
                      )}

                      <Label check>
                        <Input
                          className="mt-0"
                          style={{ marginBottom: '16px' }}
                          type="checkbox"
                          disabled={isSaving}
                          checked={selectedDevices.includes(device.deviceId)}
                          onChange={() => {
                            if (selectedDevices.includes(device.deviceId)) {
                              setDeviceSelection(
                                selectedDevices.filter((x) => x !== device.deviceId),
                              );
                            } else {
                              setDeviceSelection([...selectedDevices, device.deviceId]);
                            }
                          }}
                        />

                        <div className="pr-5">
                          <span className="text-monospace">{device.deviceId}</span>
                          <div style={{ fontSize: '0.8em' }}>{getTypeName(device.deviceType)}</div>
                        </div>
                      </Label>
                    </div>
                  </td>
                  <td>{configText(device, 'name', device.name)}</td>
                  <td>{configText(device, 'group', device.group)}</td>
                  {showColocationGroup && (
                    <td>{configText(device, 'groupname', colocationGroupName(project, device))}</td>
                  )}
                  <td>
                    <div
                      style={{ padding: '5px', display: 'inline-block' }}
                      className={hasChanges(device, 'color') ? 'bg-info' : undefined}
                    >
                      <CirclePicker
                        className={
                          !selectedDevices.includes(device.deviceId) || isSaving
                            ? 'batchDisabled'
                            : undefined
                        }
                        color={getDeviceChanges(device, 'color', device.color)}
                        circleSize={13}
                        circleSpacing={2}
                        width="135px"
                        onChangeComplete={
                          selectedDevices.includes(device.deviceId)
                            ? (color) => setDeviceChanges(device, 'color', color.hex)
                            : undefined
                        }
                      />
                    </div>
                  </td>
                  {['tag', 'beacon', 'meshrouter'].includes(device.deviceType) && (
                    <td>
                      {device.isPositioningActive !== undefined &&
                        configToggle(device, 'isPositioningActive', device.isPositioningActive)}
                    </td>
                  )}
                  {['tag'].includes(device.deviceType) ? (
                    <>
                      <td>
                        {configToggle(device, 'neighborsPost', device.config?.post?.neighbors)}
                      </td>
                      <td>{configToggle(device, 'tof', device.config?.tof?.o)}</td>
                    </>
                  ) : (
                    <>
                      <td />
                      <td />
                      <td />
                    </>
                  )}

                  {['tag', 'mioty'].includes(device.deviceType) ? (
                    <>
                      <td>
                        {configInterval(
                          device,
                          'temperaturePost',
                          device.config?.post?.temperature,
                        )}
                        {configEvent(device, 'temperatureEvent', device.config?.event?.temperature)}
                      </td>
                      {showCurrentmeter && (
                        <>
                          <td>
                            {configInterval(
                              device,
                              'currentmeterPost',
                              device.config?.post?.currentmeter,
                            )}
                            {configEvent(
                              device,
                              'currentmeterEvent',
                              device.config?.event?.currentmeter,
                            )}
                          </td>
                          <td>
                            {configExternalSensor(
                              device,
                              'currentmeterTransformationFactor',
                              device.config?.exts?.cm?.f,
                            )}
                            {configExternalSensor(
                              device,
                              'currentmeterSamplingPeriod',
                              device.config?.exts?.cm?.s,
                            )}
                          </td>
                        </>
                      )}
                      <td>{configInterval(device, 'usagePost', device.config?.post?.usage)}</td>
                      <td>
                        {configInterval(
                          device,
                          'accelerometerPost',
                          device.config?.post?.accelerometer,
                        )}
                      </td>
                      <td>{configEvent(device, 'imp', device.config?.imp)}</td>
                      <td>
                        {configInterval(
                          device,
                          'magnetometerPost',
                          device.config?.post?.magnetometer,
                        )}
                      </td>

                      {showHumidity && (
                        <td>
                          {configInterval(device, 'humidityPost', device.config?.post?.humidity)}
                          {configEvent(device, 'humidityEvent', device.config?.event?.humidity)}
                        </td>
                      )}

                      {showLight && (
                        <td>
                          {configInterval(device, 'lightPost', device.config?.post?.light)}
                          {configEvent(device, 'lightEvent', device.config?.event?.light)}
                        </td>
                      )}

                      {showPressure && (
                        <td>
                          {configInterval(device, 'pressurePost', device.config?.post?.pressure)}
                          {configEvent(device, 'pressureEvent', device.config?.event?.pressure)}
                        </td>
                      )}
                      {showSensorbridge && (
                        <>
                          <td>
                            {configInterval(
                              device,
                              'sensorbridgeCurrentloopPost',
                              device.config?.post?.sensorbridgeCurrentloop,
                            )}
                            {configExternalSensor(
                              device,
                              'sensorbridgeCurrentloopSamplingPeriod',
                              // Default value not present in config
                              device.config?.exts?.sb?.f?.[1] ?? 1000,
                            )}
                          </td>
                          <td>
                            {configInterval(
                              device,
                              'sensorbridgeRTDPost',
                              device.config?.post?.sensorbridgeRTD,
                            )}
                            {configExternalSensor(
                              device,
                              'sensorbridgeRTDSamplingPeriod',
                              // Default value not present in config
                              device.config?.exts?.sb?.r?.[1] ?? 1000,
                            )}
                          </td>
                          <td>
                            {configInterval(
                              device,
                              'sensorbridgeDigitalinPost',
                              device.config?.post?.sensorbridgeDigitalin,
                            )}
                            {configExternalSensor(
                              device,
                              'sensorbridgeDigitalinSamplingPeriod',
                              // Default value not present in config
                              device.config?.exts?.sb?.q?.[1] ?? 1000,
                            )}
                          </td>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <td />
                      <td />
                      <td />
                      {showCurrentmeter && (
                        <>
                          <td />
                          <td />
                        </>
                      )}
                      <td />
                      <td />
                      <td />
                      {showHumidity && <td />}
                      {showLight && <td />}
                      {showPressure && <td />}
                      {showSensorbridge && (
                        <>
                          <td />
                          <td />
                          <td />
                        </>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Card>
    );
  },
);
