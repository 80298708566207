export const hideRegion = (subjectString: string | undefined): string => {
  const version_string = String(subjectString);

  const ENV = process.env.NODE_ENV || null;

  if (ENV === 'production') {
    const cleaned_version = version_string.replace(/([-|_|])?(US|AU|EU)([-|_])?/g, '');
    return cleaned_version;
  }
  return version_string;
};
