import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Card,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Label,
  Dropdown,
  Input,
  Alert,
  Spinner,
  Collapse,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import { observer, useObserver } from 'mobx-react';
import { GiTransform } from 'react-icons/gi';
import { VscInfo } from 'react-icons/vsc';
import { CgDanger } from 'react-icons/cg';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { DeviceIcon } from '../../Devices/Table/components/DeviceIcon';
import { DeviceType } from '../../../../../../stores/types/Device';
import { useProjectStore } from '../../../../../../stores/ProjectStore';
import { useProfileStore } from '../../../../../../stores/ProfileStore';
import { useThemeStore } from '../../../../../../stores/ThemeStore';
import { notifyError } from '../../../../../../UI/components/notifications/error';
import { notifySuccess } from '../../../../../../UI/components/notifications/success';
import { getTypeName } from '../../../../../../helpers/deviceType';
import '../devices.css';

interface ColumnTitle {
  title: string;
}

const useStoreData = () => {
  const { projectStore } = useProjectStore();
  const { themeStore } = useThemeStore();
  const { profileStore } = useProfileStore();

  return useObserver(() => ({
    devices: projectStore.devices,
    userRolePermissions: profileStore.rolePermissions,
    getDevices: projectStore.getDevices,
    project: projectStore.project,
    deviceViewPort: themeStore.deviceViewPort,
    transformDevices: projectStore.transformDevices,
  }));
};

export const DeviceTypeTransformation = observer((): any => {
  const { userRolePermissions, devices, getDevices, deviceViewPort, project, transformDevices } =
    useStoreData();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [newType, setNewType] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [sameTypeDevices, setSameTypeDevices] = useState<string[]>([]);
  const [warningMessage, setWarningMessage] = useState<string[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [filteredDevices, setFilteredDevices] = useState<DeviceType[]>([]);
  const [showButtons, setShowButtons] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const rolePermissions: string[] = [...userRolePermissions];
  const showCoLocationGroup = rolePermissions && rolePermissions.includes('TAGS_CO-LOCATION');

  const DROPDOWN_LIST = [
    { name: 'tag', displayName: 'Sense360' },
    { name: 'meshrouter', displayName: 'Mesh Router' },
    { name: 'beacon', displayName: 'Positioning Beacon' },
    { name: 'mioty', displayName: 'MiotySense360' },
  ];

  if (!project || !devices) {
    return <></>;
  }

  const filterChameleonDevices = useCallback(() => {
    const chameleonDevices = devices.filter((device: DeviceType) =>
      device.model?.includes('CHAMELEON'),
    );
    return chameleonDevices;
  }, [devices]);

  useEffect(() => {
    if (filteredDevices.length == 0) {
      const chameleonDevices: DeviceType[] = filterChameleonDevices();
      setFilteredDevices(chameleonDevices);
    }
  }, [filteredDevices, filterChameleonDevices]);

  const filterDevices = (searchKey: string) => {
    if (searchKey.length == 0) {
      setFilteredDevices(filterChameleonDevices());
    } else {
      let newFilteredDevices: DeviceType[] = [];
      const words = searchKey.toLowerCase().split(/ /g);

      newFilteredDevices = filteredDevices.filter((x) => {
        for (const word of words) {
          if (
            x.deviceId.toLowerCase().includes(word) ||
            (x.name && x.name.toLowerCase().includes(word)) ||
            (x.group && x.group.toLowerCase().includes(word))
          ) {
            return true;
          }
        }
        return false;
      }) as DeviceType[];
      setFilteredDevices(newFilteredDevices);
    }
  };

  const resetState = () => {
    setSelectedDevices([]);
    setWarningMessage([]);
    setSameTypeDevices([]);
    setShowButtons(false);
    setNewType(null);
    setLoading(false);
    setError(null);
  };

  const resetMessageState = () => {
    setShowButtons(false);
    setSameTypeDevices([]);
    setWarningMessage([]);
  };

  const handleAllDeviceSelection = () => {
    setSelectedDevices(filteredDevices.map((x: { deviceId: string }) => x.deviceId));
    resetMessageState();
  };

  const handleDeviceSelection = (deviceType: string) => {
    setSelectedDevices(
      filteredDevices
        .filter((x) => x.deviceType === deviceType)
        .map((x: { deviceId: string }) => x.deviceId),
    );
    resetMessageState();
  };

  const deviceSelectionButton = (onClickFunction: any, message: string, type?: string) => {
    return (
      <Button
        onClick={() => onClickFunction(type)}
        size="sm"
        color="primary"
        className="ml-1"
        style={{ marginBottom: '10px' }}
      >
        {t(`${message}`)}
      </Button>
    );
  };

  const renderBatchButtons = () => {
    if (!selectedDevices) {
      return <></>;
    }

    return (
      <div className="mb-4 mt-2 batch-buttons">
        <div>
          <b>{t('DEVICES.SELECT')}</b>
          {deviceSelectionButton(handleDeviceSelection, 'DEVICES.ONLY_TRAKSENSE360', 'tag')}
          {deviceSelectionButton(handleDeviceSelection, 'DEVICES.ONLY_MIOTY', 'mioty')}
          {deviceSelectionButton(handleDeviceSelection, 'DEVICES.ONLY_BEACONS', 'beacon')}
          {deviceSelectionButton(handleDeviceSelection, 'DEVICES.ONLY_MESH_ROUTERS', 'meshrouter')}
          {deviceSelectionButton(handleAllDeviceSelection, 'DEVICES.ALL')}
          {deviceSelectionButton(resetState, 'DEVICES.NONE')}
        </div>
        <div>
          <Input
            className="batch-buttons-input"
            type="text"
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
              filterDevices(event.target.value)
            }
            placeholder={t('COMMON.SEARCH_ID_NAME')}
          />
        </div>
      </div>
    );
  };

  const TypeDropdown = () => {
    const device_ = DROPDOWN_LIST.filter((type) => type.name == newType);
    if (device_.length > 0) {
      const deviceName = device_[0].displayName;
      return deviceViewPort === 'xs' ? (
        <>
          {deviceName}
          <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown} />
        </>
      ) : (
        <>
          <b>{`${t('DEVICES.DEVICES_TYPE')}: ${deviceName}`}</b>
          <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown} />
        </>
      );
    } else {
      return (
        <>
          <b>{t('DEVICES.SELECT_DEVICE_TYPE')}</b>
          <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown} />
        </>
      );
    }
  };

  const colocationGroupName = (device: DeviceType) => {
    const result = project.colocationGroups?.find(
      (gr: { incrementiveId?: number }) => gr.incrementiveId === device?.config?.loc?.g,
    );
    if (result) {
      return result?.name;
    }
    return '';
  };

  const transformDeviceType = async () => {
    try {
      await transformDevices(selectedDevices, newType);
      await getDevices(project.organizationId, project.id);
      resetState();

      selectedDevices.length === 1
        ? notifySuccess(
            t('DEVICES.TRANSFORMED_DEVICE_SUCCESS', {
              count: selectedDevices.length,
            }),
          )
        : notifySuccess(
            t('DEVICES.TRANSFORMED_DEVICES_SUCCESS', {
              count: selectedDevices.length,
            }),
          );
    } catch (error: any) {
      if (error.response) {
        resetState();
        notifyError(error.response.data.message);
        setError(error.response.data.message);
      } else {
        resetState();
        notifyError(t('GLOBAL.NETWORK_ERROR'));
        setError(t('DEVICES.NETWORK_ERROR'));
      }
    }
  };

  const deviceTransformMessage = (): any => {
    let currentTypes: string[] = [];
    const devicesToTransform: DeviceType[] = [];

    selectedDevices &&
      selectedDevices.map((d) => {
        const deviceDetails = filterChameleonDevices().filter(
          (device_: DeviceType) => device_.deviceId === d,
        )[0];
        devicesToTransform.push(deviceDetails);
      });

    devicesToTransform &&
      devicesToTransform.map((x: DeviceType) => {
        currentTypes.push(x.deviceType);
      });

    currentTypes = [...new Set(currentTypes)];

    // This function should use the actual device type, and not the formatted device type
    // https://wittra.atlassian.net/browse/CL-2949
    const messages = currentTypes.map((currentType: string) => {
      if (currentType !== newType && newType !== '') {
        const toDeviceType = `${t(
          `DEVICES.TRANSFORM_TO_${
            newType && getTypeName(newType).replace(/\s+/g, '').toUpperCase()
          }`,
        )}`;
        if (currentType !== 'tag') {
          return `${t(
            `DEVICES.TRANSFORM_FROM_${getTypeName(currentType).replace(/\s+/g, '').toUpperCase()}`,
          )} ${toDeviceType}`;
        } else {
          return toDeviceType;
        }
      } else if (currentType === newType) {
        const sameDeviceType = devicesToTransform
          .filter((x: DeviceType) => {
            return x.deviceType === newType;
          })
          .map((d: DeviceType) => {
            return d.deviceId;
          });

        setSameTypeDevices([...sameDeviceType]);
      }
    });

    return setWarningMessage([...messages] as string[]);
  };

  const getInfoMessage = (): any => {
    return (
      <p>
        {t('DEVICES.REQUIREMENTS1')} <br /> {t('DEVICES.REQUIREMENTS2')} <br />
        {t('DEVICES.REQUIREMENTS3')} <br /> {t('DEVICES.REQUIREMENTS4')} <br />
        {t('DEVICES.REQUIREMENTS5')}
      </p>
    );
  };

  const renderChameleonDevices = () => {
    return filteredDevices.map((device: DeviceType) => {
      return (
        <div
          key={device.deviceId}
          className={
            'deviceTableRow' + (selectedDevices?.includes(device.deviceId) ? ' selected' : '')
          }
        >
          <div className="deviceTableColumn deviceTableColumnCheckbox">
            <div className="chameleon-device-div">
              <Label check>
                <Input
                  className="mt-0 chameleon-device-checkbox"
                  type="checkbox"
                  checked={selectedDevices.includes(device.deviceId)}
                  onChange={() => {
                    if (selectedDevices.includes(device.deviceId)) {
                      setSelectedDevices(selectedDevices.filter((x) => x !== device.deviceId));
                    } else {
                      setSelectedDevices([...selectedDevices, device.deviceId]);
                    }
                    setSameTypeDevices([]);
                    setWarningMessage([]);
                    setShowButtons(false);
                  }}
                />
              </Label>
            </div>
          </div>
          <div className="deviceTableColumn deviceTableColumnType">
            <DeviceIcon deviceType={device.deviceType} />
          </div>
          <div className="deviceTableColumn deviceTableColumnIdAndName">
            {device.deviceId && (
              <div className="font-weight-normal">
                {device.deviceId}
                <div className="font-weight-light">{getTypeName(device.deviceType)}</div>
              </div>
            )}
          </div>
          <div className="deviceTableColumn deviceTableColumnIdAndName">
            <div className="font-weight-normal">{device.name}</div>
          </div>
          <div className="deviceTableColumn deviceTableColumnIdAndName">
            <div className="font-weight-normal">{device.group}</div>
          </div>
          {showCoLocationGroup && (
            <div className="deviceTableColumn deviceTableColumnIdAndName">
              <div className="font-weight-normal">{colocationGroupName(device)}</div>
            </div>
          )}
        </div>
      );
    });
  };

  const handledeviceTypeSelection = (type: string) => {
    setNewType(type);
    setSameTypeDevices([]);
    setWarningMessage([]);
    setShowButtons(false);
    setError(null);
  };

  const TransormTableColumn = ({ title }: ColumnTitle) => {
    return (
      <div className="deviceTableColumn deviceTableColumnIdAndName">
        <span className="ml-2">
          <b>{title}</b>
        </span>
      </div>
    );
  };

  return (
    <Card className="p-5 mt-3">
      <div className="app-page-title app-page-title-div">
        <div className="page-title-wrapper" style={{ marginRight: '2.5rem', marginLeft: '2.5rem' }}>
          <div id="page-title-heading-div" className="page-title-heading">
            <div id="page-title-icon-div" className={cx('page-title-icon', { 'd-none': false })}>
              <GiTransform />
            </div>

            <div>
              <b>{t('DEVICES.DEVICE_TYPE_TRANSFORMATION')}</b>
            </div>
          </div>

          <div className="page-title-actions">
            <Button
              onClick={() =>
                navigate('/organizations/' + project.organizationId + '/projects/' + project.id)
              }
              size="lg"
              color="link"
            >
              {t('DEVICES.BACK')}
            </Button>
            {!showButtons && (
              <Button
                onClick={() => {
                  deviceTransformMessage();
                  setShowButtons(sameTypeDevices?.length === 0);
                }}
                size="lg"
                color="primary"
                type="button"
                style={{ cursor: 'pointer' }}
                disabled={selectedDevices.length === 0 || !newType}
              >
                {t('DEVICES.TRANSFORM')}
              </Button>
            )}
            {showButtons && (
              <>
                <Button
                  onClick={() => {
                    resetState();
                  }}
                  size="lg"
                  color="primary"
                  type="button"
                  className="pointer"
                  disabled={(selectedDevices.length === 0 && !newType) || loading === true}
                >
                  {t('GLOBAL.CANCEL')}
                </Button>
                <span className="ml-2">
                  <Button
                    onClick={() => {
                      transformDeviceType();
                      setNewType(null);
                      setLoading(true);
                    }}
                    size="lg"
                    color="primary"
                    type="button"
                    className="pointer"
                    disabled={
                      selectedDevices.length == 0 || !newType || sameTypeDevices?.length > 0
                    }
                  >
                    <span>{t('DEVICES.PROCEED')}</span>
                    <span className="ml-2">
                      {loading && <Spinner size="sm" color="light" type="border" />}
                    </span>
                  </Button>
                </span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row pl-5 pt-3 pr-5" style={{ display: 'flex', flexDirection: 'column' }}>
        <p className="m-0">{t('DEVICES.DEVICE_TRANSFORMATION_DESC')}</p>
        <Alert color="warning mt-3 alert">
          <div className="alert-div">
            <div className="m-3">
              <span>
                <Tooltip
                  prefixCls="rc-slider-tooltip"
                  overlay={t('DEVICES.REQUIREMENTS')}
                  placement="bottom"
                >
                  <CgDanger className="mr-2 mt-1 h5" />
                </Tooltip>
              </span>
              <span>{t('DEVICES.DEVICE_TRANSFORMATION_CAUTION')}</span>
            </div>
            <div>
              <Button
                color="warning"
                onClick={() => setCollapse(!collapse)}
                style={{
                  margin: '1rem',
                  cursor: 'pointer',
                }}
              >
                {t('DEVICES.SHOW_REQUIREMENTS')}
              </Button>
              <Collapse isOpen={collapse}>
                <Alert
                  color="warning"
                  style={{
                    maxWidth: '800px',
                    marginLeft: '15px',
                  }}
                >
                  {getInfoMessage()}
                </Alert>
              </Collapse>
            </div>
          </div>
        </Alert>
      </div>
      <hr />
      <CardBody className="card-body">
        {renderBatchButtons()}
        <div className="mb-3 mt-2 card-body-div">
          <b>
            {selectedDevices.length === 0
              ? t('DEVICES.TRANSFORM_SELECTED')
              : selectedDevices.length === 1
              ? t('DEVICES.TRANSFORM_DEVICE')
              : t('DEVICES.TRANSFORM_DEVICES', {
                  count: selectedDevices.length,
                })}
            :
          </b>
          <div className="ml-4">
            <Dropdown
              isOpen={dropDownOpen}
              toggle={() => setDropDownOpen(!dropDownOpen)}
              direction="down"
              className="drop-down-width"
            >
              <DropdownToggle>{TypeDropdown()}</DropdownToggle>
              <DropdownMenu container="body">
                <div className="scroll-area-xs dropdown-menu-div">
                  <PerfectScrollbar>
                    {Object.values(DROPDOWN_LIST).map((type, index) => {
                      return (
                        <DropdownItem
                          key={`${index}-${type}`}
                          onClick={() => handledeviceTypeSelection(type.name)}
                          className="drop-down-width"
                        >
                          {type.displayName}
                        </DropdownItem>
                      );
                    })}
                  </PerfectScrollbar>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {error !== null && notifyError(error)}
        {warningMessage?.filter((x) => x !== undefined).length > 0 && (
          <Alert color="warning" className="alert mb-2">
            <span>
              <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={t('DEVICES.REQUIREMENTS')}
                placement="right"
                overlayInnerStyle={{
                  width: 'auto',
                  height: 'auto',
                  fontSize: '15px',
                }}
              >
                <VscInfo className="mt-1 h5" />
              </Tooltip>
            </span>
            {warningMessage.map((message: string, index: number) => {
              return (
                message && (
                  <span className="alert-div ml-2" key={`alert-${message}-${index}`}>
                    {message} <br />
                  </span>
                )
              );
            })}
          </Alert>
        )}
        {sameTypeDevices && sameTypeDevices.length > 0 && (
          <Alert color="danger" className="alter">
            <div className="alert-div">
              <b>{sameTypeDevices.join(', ')}</b> {t('DEVICES.TRANSFORM_SAME_TYPE')}
            </div>
          </Alert>
        )}
        <hr />

        <div className="deviceTable">
          <div className="deviceTableRow">
            <div className="deviceTableColumn deviceTableColumnCheckbox" />
            <div className="deviceTableColumn deviceTableColumnType" />
            <TransormTableColumn title={t('COMMON.DEVICE')} />
            <TransormTableColumn title={t('COMMON.NAME')} />
            <TransormTableColumn title={t('DEVICES.GROUP')} />
            {showCoLocationGroup && <TransormTableColumn title={t('DEVICES.COLOCATION_GROUP')} />}
          </div>

          {filteredDevices.length > 0 ? (
            renderChameleonDevices()
          ) : (
            <div className="m-5 align">{t('DEVICES.NO_CHAMELEON_DEVICES_MATCH')}</div>
          )}
        </div>
      </CardBody>
    </Card>
  );
});
