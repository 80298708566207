import React from 'react';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import './Popup.css';

interface IPopup {
  title: string;
  content: string;
  buttonLabel: string;
  onClose: () => void;
}

export const Popup = ({ title, content, buttonLabel, onClose }: IPopup): JSX.Element => {
  return (
    <>
      <div className="overlay" />
      <div className="popup">
        <div className="header">{title}</div>
        <div className="content">{content}</div>
        <div className="footer">
          <LaddaButton
            className="mr-2 btn btn-primary"
            onClick={onClose}
            data-style={EXPAND_RIGHT}
            disabled={false}
          >
            {buttonLabel}
          </LaddaButton>
        </div>
      </div>
    </>
  );
};

export default Popup;
