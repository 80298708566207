import { Axios } from './axios';
import { getHeaders } from './getHeaders';

const BASE_URL = process.env.REACT_APP_BASE_API || null;

interface IPaginationResponse<T> {
  items: T[];
  page: number;
  perPage: number;
  total: number;
}

interface IPaginationParams {
  page?: number;
  perPage?: number;
}

export const accumulatePages = async <T>(
  resource: string,
  queryParams: any,
  token: string,
  paginationParams: IPaginationParams = {},
  items: T[] = [],
): Promise<T[]> => {
  const { page = 1, perPage = 50 } = paginationParams;
  const headers = getHeaders(token);
  const requestConfig = {
    url: `${BASE_URL}/${resource}`,
    headers,
    params: queryParams,
    paramsSerializer: { indexes: null },
    method: 'GET',
  };

  const response = await Axios(requestConfig);
  const { items: responseItems, total: totalItems } = response.data as IPaginationResponse<T>;
  const accumulatedItems = [...items, ...responseItems];

  if (responseItems.length === perPage && accumulatedItems.length < totalItems) {
    return accumulatePages(
      resource,
      queryParams,
      token,
      { page: page + 1, perPage },
      accumulatedItems,
    );
  } else {
    return accumulatedItems;
  }
};

export const getAllPages = async <T>(
  resource: string,
  queryParams: any,
  token: string,
): Promise<T[]> => {
  return accumulatePages(resource, queryParams, token);
};
