export const isLegacyGateway = (createdAt: string): boolean => {
  const ioTCoreDeprecatedDate = new Date('2023-08-16');
  const legacyGwManufactureDate = new Date('2023-05-22');
  const now = new Date();
  if (ioTCoreDeprecatedDate < now) {
    return new Date(createdAt) < legacyGwManufactureDate;
  } else {
    return false;
  }
};
