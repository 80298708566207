const versionUpgradeDowngrade = (newVersion: string[], currentVersion: string[]): boolean => {
  switch (currentVersion[0]) {
    case newVersion[0]:
      return false;
    case 'v5':
      return currentVersion[1] >= '1' ? newVersion[0] !== 'v6' : true;
    case 'v6':
      return newVersion[0] !== 'v7';
    case 'v7':
      return newVersion[0] !== 'v8';
    default:
      return true;
  }
};

export default versionUpgradeDowngrade;
