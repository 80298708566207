import { toast, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const notifyWarning = (message: string, position: ToastPosition = 'top-center') => {
  toast.warn(message, {
    position,
    autoClose: 5000,
    hideProgressBar: true,
  });
};
