import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface TabProps {
  index: number;
  label: string;
  component: JSX.Element;
  disabled?: boolean;
}

const TabPanel = ({ children, index, value }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box>
          <>{children}</>
        </Box>
      )}
    </div>
  );
};

interface CommonTabsProps {
  tabs: TabProps[];
  children?: JSX.Element;
  noBorder?: boolean;
}
interface ControlledTabs extends CommonTabsProps {
  activeTab?: never;
  currentTab: number;
  setTab: (tab: number) => void;
}

interface InternalTabs extends CommonTabsProps {
  activeTab?: number;
  currentTab?: never;
  setTab?: never;
}

/**
 * The TabComponent has two versions depending on the use case;
 * the internalTabs version relies on its own internal state to keep track of the current tab,
 * use this when the tabs are just controlled using the tabs
 * whereas the ControlledTabs version uses an external state to show the current tab
 * use this when you need to switch tabs programatically
 */

export type TabsProps = InternalTabs | ControlledTabs;

export const TabComponent = ({
  tabs,
  children,
  noBorder,
  activeTab,
  currentTab,
  setTab,
}: TabsProps): JSX.Element => {
  const [value, setValue] = useState(activeTab ? activeTab : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setTab && setTab(newValue);
  };

  if (tabs.length === 1) {
    return tabs[0].component;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: noBorder ? 0 : 1, borderColor: 'divider' }}>
        <Tabs
          value={currentTab ?? value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab) => (
            <Tab key={`label-${tab.label}-${tab.index}`} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {children}
      {tabs.map((tab) => {
        return (
          <TabPanel
            value={currentTab ?? value}
            index={tab.index}
            key={`child-${tab.label}-${tab.index}`}
          >
            {tab.component}
          </TabPanel>
        );
      })}
    </Box>
  );
};
