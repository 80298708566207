export const processImpactValues = (values: any, cloneaxis?: boolean): any => {
  if (cloneaxis) {
    const axesValues: string[] = Object.values(values);
    const threshold: number | undefined = Number(axesValues.shift()) * 1000;
    const binary = axesValues.join('');
    const axes = parseInt(binary, 2);
    const cloneAxes = {
      t: threshold,
      e: axes,
    };
    return cloneAxes;
  } else {
    let T, X, Y, Z;
    const e = values && values.e;
    const binaryString = e && e.toString(2);
    if (values && values.t !== undefined) {
      T = values.t <= 16 ? values.t : values.t / 1000;
    }
    if (e !== undefined) {
      let binary;
      if (e >= 0 && e <= 1) {
        binary = '00'.concat(binaryString);
      } else if (e >= 2 && e <= 3) {
        binary = '0'.concat(binaryString);
      } else {
        binary = binaryString;
      }
      X = Number(binary.charAt(0));
      Y = Number(binary.charAt(1));
      Z = Number(binary.charAt(2));
    } else if (
      values &&
      (values.x_axis !== undefined || values.y_axis !== undefined || values.z_axis !== undefined)
    ) {
      X = values.x_axis;
      Y = values.y_axis;
      Z = values.z_axis;
    }
    return {
      t: T || 0,
      x_axis: X || 0,
      y_axis: Y || 0,
      z_axis: Z || 0,
    };
  }
};
