import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer, useObserver } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { LoadingOverlay } from '../../../UI/components/LoadingOverlay';
import { useProjectStore } from '../../../stores/ProjectStore';
import { useThemeStore } from '../../../stores/ThemeStore';
import { useProfileStore } from '../../../stores/ProfileStore';
import { Popup } from '../../../components/common/Popup/Popup';
import { TabComponent, TabProps } from '../../../components/common/Tabs/Tabs';
import { DeviceConfiguration } from './DeviceConfiguration';
import { possiblePostIntervals } from './helpers/postingIntervals';
import { DeviceTypeTransformation } from './components/Devices/DevicesTransformation/DeviceTypeTransformation';

const useStoreData = () => {
  const { projectStore } = useProjectStore();
  const { profileStore } = useProfileStore();
  const { themeStore } = useThemeStore();

  return useObserver(() => ({
    userRolePermissions: profileStore.rolePermissions,
    project: projectStore.project,
    selectProject: projectStore.selectProject,
    devices: projectStore.devices,
    getDevices: projectStore.getDevices,
    selectedDevices: projectStore.selectedDevices,
    setDeviceSelection: projectStore.setDeviceSelection,
    selectedTab: themeStore.selectedTab,
    deviceViewPort: themeStore.deviceViewPort,
    setSelectedTab: themeStore.setSelectedTab,
  }));
};

export const ProjectDevicesShow = observer(() => {
  const { project, selectProject, devices, getDevices, setSelectedTab } = useStoreData();

  const params = useParams();
  const { t } = useTranslation();
  const [hasRequestedProject, setHasRequestedProject] = useState(false);
  const [hasRequestedDevices, setHasRequestedDevices] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [intervals, setIntervals] = useState(possiblePostIntervals);
  const [warnUser, setWarnUser] = useState(false);

  useEffect(() => {
    setSelectedTab(0);
    if (!hasRequestedProject) {
      setHasRequestedProject(true);

      if (!project && params.projectId && params.orgId) {
        selectProject(params.orgId, params.projectId);
      }
    }
  }, [project, hasRequestedProject, selectProject, setSelectedTab, params]);

  useEffect(() => {
    if (project && !hasRequestedDevices) {
      setHasRequestedDevices(true);

      setIntervals(possiblePostIntervals);

      if (!devices) {
        getDevices(project.organizationId, project.id);
      }
    }
  }, [project, devices, hasRequestedDevices, getDevices]);

  if (!project) {
    return <></>;
  }

  const renderLoader = () => (
    <LoadingOverlay loading={true}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '300px',
        }}
      >
        <p>{t('GLOBAL.LOADING')}</p>
      </div>
    </LoadingOverlay>
  );

  if (!devices) {
    return <></>;
  }

  const ConfigTab = (
    <Suspense fallback={renderLoader()}>
      <DeviceConfiguration
        isSaving={isSaving}
        setIsSaving={(value: boolean) => setIsSaving(value)}
        errors={errors}
        setErrors={(v) => setErrors(v)}
        intervals={intervals}
        setWarnUser={(v: boolean) => setWarnUser(v)}
      />
    </Suspense>
  );

  const tabs: TabProps[] = [
    {
      index: 0,
      label: t('DEVICES.CONFIGURATION'),
      component: ConfigTab,
    },
    {
      index: 1,
      label: t('DEVICES.TRANSFORMATION'),
      component: <DeviceTypeTransformation />,
    },
  ];

  return (
    <>
      {warnUser && (
        <Popup
          title={t('COMMON.WARNING')}
          buttonLabel={t('COMMON.UNDERSTAND')}
          content={t('DEVICES.MANUAL_UPDATE_MIOTY')}
          onClose={() => setWarnUser(false)}
        />
      )}
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx('page-title-icon', { 'd-none': false })}>
              <i className="pe-7s-tools" />
            </div>

            <div>
              {t('DEVICES.MANAGE_DEVICES', {
                project: project.name,
              })}
            </div>
          </div>
        </div>
      </div>

      <TabComponent tabs={tabs} noBorder></TabComponent>
    </>
  );
});
